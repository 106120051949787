.span {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex: 1 1 0;
  height: 100%;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  padding: 4px var(--spacing-spacing-xxx-small);
  color: var(--color-dominant-on-dominant);
  vertical-align: middle;
  transition: background-color var(--transition-hover);

  &.disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  &.button {
    cursor: pointer;
  }

  .icon {
    margin-right: 8px;
    z-index: 9;
  }

  .react {
    margin-right: var(--spacing-spacing-xxx-small);
  }
}

