@use "css/scss/typography";

.wrapper {
  position: relative;

  .select {
    width: 100%;
    transition: background-color .3s ease;

    // Mouse events
    :global(.MuiOutlinedInput-notchedOutline) {
      border: var(--border-thin);
    }

    &:global(.Mui-disabled) {
      border-color: var(--color-dominant-tertiary);
      opacity: 50%;
    }

    &:hover:not(:global(.Mui-disabled)) :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--color-secondary-900);
    }

    &:active,
    &:focus,
    &:global(.Mui-focused) {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-dominant-tertiary);
      }
    }

    :global(.MuiSelect-select) {
      -webkit-text-fill-color: unset;
    }

    .placeholder,
    .content {
      @include typography.label-default;

      &.activePlaceholder {
        color: var(--color-dominant-tertiary) !important;
      }
    }

    .placeholder {
      color: var(--color-tertiary-300);
    }

    .content {
      color: var(--color-dominant-on-dominant);
    }

    // Sizes
    &.small,
    &.medium {
      :global(.MuiSelect-select) {
        padding-left: var(--spacing-spacing-xx-small);
        padding-right: var(--spacing-spacing-large);

        span {
          font-size: var(--font-size-font-size-3) !important;
        }
      }

      .placeholder,
      .content {
        font-size: var(--font-size-font-size-3);
      }
    }

    &.small {
      height: var(--size-sizing-small);

      :global(.MuiOutlinedInput-notchedOutline) {
        border-radius: var(--border-radius-small);
      }
    }

    &.medium {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-radius: var(--border-radius-medium);
      }
    }

    &.large {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-radius: var(--border-radius-medium);
      }

      :global(.MuiSelect-select) {
        padding-left: var(--spacing-spacing-x-small);
        padding-right: var(--size-sizing-xx-large);
      }

      .placeholder,
      .content {
        font-size: var(--font-size-font-size-4);
      }
    }

    // Themes
    &.primary {
      transition: --startColor 0.2s, --endColor 0.2s;
      background: linear-gradient(103deg, var(--startColor) -13.82%, var(--endColor) 121.29%);
      --startColor: var(--color-green-300);
      --endColor: var(--color-blue-300);
      &:hover:not(:global(.Mui-disabled)) {
        --endColor: var(--color-green-300);
      }
      &:active, &:focus, &:global(.Mui-focused) {
        --startColor: var(--color-green-100);
        --endColor: var(--color-green-100);
      }
    }

    &.secondary {
      background-color: var(--color-secondary-color);
    }

    &.tertiary {
      background-color: var(--color-navy-100);
    }

    &.tone {
      background-color: var(--color-tertiary-090);

      :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }

      .content {
        color: var(--color-dominant-tertiary);
      }

      &:global(.Mui-disabled) {
        opacity: 50%;
      }

      &:hover:not(:global(.Mui-disabled)) {
        background-color: var(--color-primary-300);
        :global(.MuiOutlinedInput-notchedOutline) {
          border: none;
        }
      }

      &.active,
      &:active,
      &:focus,
      &:global(.Mui-focused) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border: var(--border-sizing-small) solid var(--color-dominant-on-dominant);
        }
      }
      .placeholder {
        color: var(--color-tertiary-500);
      }
    }
  }

  .icon {
    position: absolute;
    margin: auto var(--spacing-spacing-xxx-small);
    top: unset;
  }

  .tag {
    display: flex;
    place-content: center;
    margin-left: var(--spacing-spacing-thin);
    background-color: var(--color-secondary-100);
    border-radius: 50%;
    height: 16px;
    min-width: 16px;

    font-size: var(--font-size-font-size-1);
    line-height: var(--line-height-var-text);
  }
}

.menu {
  border: var(--border-thin);

  --item_height: var(--size-sizing-medium);
  --list_height_multiplier: 4.5;

  &.small {
    --item_height: var(--size-sizing-small);
  }

  &.medium {
    --item_height: var(--size-sizing-medium);
  }

  &.large {
    --item_height: var(--size-sizing-large);
  }

  &:has(ul):has(li.subheader) {
    --list_height_multiplier: 5.5;
  }

  &:not(.autoHeight) {
    max-height: calc(var(--list_height_multiplier) * var(--item_height) + var(--spacing-spacing-xxx-small) + (2 * var(--border-sizing-medium)));

    &.searchable {
      max-height: calc(40px + var(--list_height_multiplier) * var(--item_height) + var(--spacing-spacing-xxx-small) + (2 * var(--border-sizing-medium)));
    }
  }

  .menuList {
    padding-top: var(--spacing-spacing-xxx-small);
    padding-bottom: var(--spacing-spacing-xxx-small);

    .subheader {
      padding-bottom: var(--spacing-spacing-xxx-small);
    }
  }

  .item {
    height: var(--item_height);
    background-color: var(--color-dominant-white);

    span {
      color: var(--color-dominant-on-dominant);
      font-weight: var(--font-weight-regular);
    }

    &.selected,
    &:global(.Mui-selected) {
      background-color: var(--color-item-selected);
      color: var(--color-secondary-900);

      &:hover {
        background: var(--color-item-hovered);
      }
    }

    &:hover,
    &:focus {
      background: var(--color-item-hovered);
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.small {
    margin-top: var(--spacing-spacing-thin);
    border-radius: var(--border-radius-small);

    .item,
    .subheader {
      padding-left: var(--spacing-spacing-xx-small);
      padding-right: var(--spacing-spacing-xx-small);

      span {
        font-size: var(--font-size-font-size-3);
      }
    }
  }

  &.medium {
    margin-top: var(--spacing-spacing-xxx-small);
    border-radius: var(--border-radius-medium);

    .item,
    .subheader {
      padding-left: var(--spacing-spacing-xx-small);

      span {
        font-size: var(--font-size-font-size-3);
      }
    }
  }

  &.large {
    margin-top: var(--spacing-spacing-xx-small);
    border-radius: var(--border-radius-medium);

    .item,
    .subheader {
      padding-left: var(--spacing-spacing-x-small);
      padding-right: var(--spacing-spacing-x-small);

      span {
        font-size: var(--font-size-font-size-4);
      }
    }
  }
}