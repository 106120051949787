$hugeIcon: 24px;
$bigIcon: 16px;
$defaultIcon: 12px;

.fullWidth {
  width: 100%;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-spacing-xx-small);
  max-width: 100%;
  font-family: var(--font-family);
  color: var(--color-dominant-on-dominant);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  background-color: var(--color-dominant-primary);
  border: none;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;

  .content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
    min-width: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    box-sizing: border-box;
    transition: opacity 150ms var(--transition-bezier);

    span {
      padding: 4px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: inherit;
      box-sizing: border-box;
    }
  }

  &.fullWidth {
    width: 100% !important;
    border-radius: var(--border-radius-medium);

    .content > span {
      justify-content: center;
    }

    &.isDropdown .content > span {
      justify-content: flex-start;
    }
  }

  &:hover:not(.disabled) {
    background-color: var(--color-primary-300);
  }

  &.selected {
    background-color: var(--color-praimary-100);
  }

  &:disabled {
    opacity: .5;

    &:hover {
      opacity: .5;
    }

    .icon {
      opacity: .8;
    }
  }

  &:focus {
    outline: none;
  }

  &.reverse {
    flex-flow: row-reverse nowrap;

    .icon {
      margin: 0 0 0 8px;
    }
  }

  .icon {
    width: $defaultIcon;
    margin: 0 8px 0 0;

    svg {
      transform: rotate(0);
      transform-origin: center;
      transition: transform 150ms linear;
      width: $defaultIcon;
      height: $defaultIcon;
      fill: var(--color-dominant-on-dominant)
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .badge {
    height: 20px;
    min-width: 20px;
    margin-left: var(--spacing-spacing-xxx-small);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-tertiary-050);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.small {
    height: 32px;
    border-radius: var(--border-sizing-large);
  }

  &.medium {
    height: 40px;
    border-radius: var(--border-radius-medium);
  }

  &.large {
    padding: 0 var(--spacing-spacing-x-small);
    height: 48px;
    font-size: 16px;
    border-radius: var(--border-radius-medium);
  }

  &.secondary {
    color: var(--color-dominant-on-dominant);
    background-color: var(--color-dominant-white);
    border: var(--border-thin);

    &.active {
      border-color: var(--color-dominant-tertiary);
    }

    &:hover {
      background-color: var(--color-tertiary-100);
    }

    &.selected {
      background-color: var(--color-tertiary-050);
    }

    &:focus {
      outline: none;
    }
  }

  &.tertiary, &.tertiaryReverse {
    color: var(--color-dominant-on-dominant);
    background-color: transparent;
    border: none;

    &.active, &.selected {
      background-color: var(--color-tertiary-050);

      .badge {
        background-color: var(--color-tertiary-100);
      }
    }

    &.selected {
      border: var(--border-thin);
    }

    &:focus {
      outline: none;
    }

    &:hover:not(.disabled) {
      background-color: var(--color-tertiary-100);
      .badge {
        background-color: var(--color-tertiary-050);
      }
    }
  }

  &.tertiaryNoBorder {
    background-color: var(--color-tertiary-025);
    &:hover:not(.disabled) {
      background-color: var(--color-tertiary-100);
    }

    &.selected {
      background-color: var(--color-tertiary-050);
    }

    &:focus {
      outline: none;
    }
  }

  &.tertiaryReverse {
    background-color: transparent;
    &:hover:not(.disabled) {
      background-color: var(--color-dominant-white);
    }
    &.selected {
      background-color: var(--color-tertiary-050);
    }
  }

  &.tertiaryNew {
    color: var(--color-dominant-on-dominant);
    background-color: var(--color-tertiary-050);

    &.active {
      border: var(--border-thin);
    }

    &:hover:not(.disabled) {
      background-color: var(--color-tertiary-100);
    }

    &.selected {
      background-color: var(--color-item-selected);
    }
  }

  &.shopify {
    color: var(--color-dominant-white);
    background-color: #008060;

    &:hover:not(.disabled) {
      background-color: rgba(#008060, .8);
    }
    &.selected {
      background-color: var(--color-tertiary-050);
    }
  }

  &.link {
    background-color: transparent;
    border: none;

    color: inherit;
    font-weight: inherit;
    font-size: inherit;

    &:hover:not(.disabled) {
      background-color: transparent;
      color: inherit;
      text-decoration: underline;
    }
    &.selected {
      background-color: var(--color-tertiary-050);
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.onlyIcon {
    width: 40px !important;
    height: 40px !important;
    padding: 0;

    &.small {
      width: 32px !important;
      height: 32px !important;
    }

    &.large {
      width: 48px !important;
      height: 48px !important;
    }

    &:focus {
      box-shadow: none;
    }

    .icon {
      margin: 0;
    }

    &:disabled .icon:hover {
      background-color: transparent;
    }
  }

  &.withIcon {
    display: flex;

    .content {
      white-space: nowrap;
    }
  }
}

.spanWrapper {
  display: inline-block;
  width: fit-content;
}
