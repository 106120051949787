

.paper {
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-tertiary-100, #BECDDC);
  background: var(--color-dominant-white);
  filter: drop-shadow(2px 2px 4px rgba(0,48,87,0.2));

  .list {
    padding: var(--size-sizing-thin) 0;
    min-width: 200px;
  }
}
.trigger {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  cursor: pointer;

  &.moderate {
    padding: 4px 6px;
    border-radius: 4px;
    background-color: var(--color-tertiary-025);
  }
}

.licence {
  display: flex;
  flex-flow: column wrap;
  padding: var(--spacing-spacing-xx-small);
  background: #F0F2F5;
  font-size: 14px;

  .top {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin-bottom: var(--spacing-spacing-xxx-small);

    .title {
      display: inline-flex;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
    }

    .remainingTime {
      display: flex;

      span {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }

  .bottom {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
  }
}

.bar {
  position: relative;
  display: flex;
  width: 100%;
  height: 4px;
  background: var(--color-dominant-white);
  border-radius: var(--border-radius-medium);
  overflow: hidden;

  .remainingTimeBar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.days {
  font-size: 12px;
}
