/* AirBnb DateRangePicker override */
.DayPickerKeyboardShortcuts__show {
	display: none;
}

.CalendarDay--hovered-span, .CalendarDay--hovered, .CalendarDay--after-hovered-start {
	background: #fff0f5;
    border: 1px double #fff0f5;
	color: var(--color-dominant-on-dominant);
}

.CalendarDay--selected-span {
	background: #fff0f5;
    border: 1px double #fff0f5;
	color: var(--color-dominant-on-dominant);
}

.CalendarDay--selected-span.CalendarDay--hovered, .CalendarDay--selected-span:active {
	background: var(--color-pink-300);
	border: 1px double var(--color-pink-300);
	color: var(--color-dominant-white);
}

.CalendarDay--selected-start, .CalendarDay--selected-end, .CalendarDay--selected {
	background: var(--color-pink-300);
    border: 1px double var(--color-pink-300);
	color: var(--color-dominant-white);
}

.DateInput__display-text--focused {
	background: #fff0f5;
    border-color: #fff0f5;
	color: var(--color-dominant-on-dominant);
}

.CalendarDay--blocked-out-of-range .CalendarDay__button {
	cursor: auto;
}
.CalendarDay--blocked-out-of-range.CalendarDay--hovered {
	color: #cacccd;
	background: var(--color-dominant-white);
	border: 1px solid #e4e7e7;
}

.DateRangePickerInput__clear-dates:focus, .DateRangePickerInput__clear-dates--hover {
	background: none;
}

.DateRangePicker__picker--portal {
	z-index: 1000;
}

.DateRangePicker {
	display: block;
	// position: relative;
}

.DateRangePickerInput {
	display: flex;
	align-items: center;
	.DateInput {
		flex-grow: 1;
	}
}

/* MODAL CHANGE STATE ADMIN */

label.is-error {
	&>span {
		color: red;
	}
}

/* Mobile */
@media screen and (max-width: 640px) {
	.DateInput {
		font-size: 15px;
	}
}

/* Small mobile screens (iPhone 5) */
@media screen and (max-width: 320px) {
	.DateInput {
		font-size: 13px;
	}
}
