@mixin buttonLabel_large {
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-4);
}

@mixin buttonLabel_default {
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-3);
}

@mixin inputLabel_large {
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-4);
  color: var(--color-secondary-900)
}

@mixin inputLabel_default {
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-3);
  color: var(--color-secondary-900)
}

@mixin text_default {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-font-size-4);
  line-height: var(--line-height-text);
  color: var(--color-dominant-tertiary)
}

@mixin text_secondary {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-font-size-4);
  line-height: var(--line-height-text);
  color: var(--color-tertiary-400)
}

@mixin page-title {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-9);
  line-height: var(--line-height-heading);
  color: var(--color-tertiary-600)
}

@mixin header-row-title {
  font-family: var(--font-family);
  font-size: var(--font-size-font-size-4);
  line-height: var(--line-height-var-text);
  color: var(--color-dominant-tertiary)
}

@mixin kol_identity {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-font-size-4);
  line-height: var(--line-height-text);
  color: var(--color-dominant-tertiary)
}

@mixin kol_username {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-font-size-3);
  line-height: var(--line-height-text);
  color: var(--color-tertiary-500)
}

@mixin chart_legend-hightlight {
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-font-size-3);
  line-height: var(--line-height-text);
}

@mixin chart_legend {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-font-size-3);
  line-height: var(--line-height-text);
}

@mixin tooltip_text {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-font-size-3);
  line-height: var(--line-height-text);
}

@mixin tooltip_text-var {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-font-size-3);
  line-height: var(--line-height-text);
}

// ------ Mixins from var.scss
@mixin placeholder {
  color: var(--color-tertiary-300);
  font-size: 13px;
}

@mixin borderFocus {
  outline: none;
  border-color: var(--color-dominant-secondary) !important;
  box-shadow: 0 0 0 3px var(--color-navy-100) !important;
}

@mixin resetBorderFocus {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

@mixin shadow4 {
  box-shadow: 0 14px 28px color-mix(in srgb, #003057, transparent 25%), 0 10px 10px color-mix(in srgb, #003057, transparent 22%);
}

@mixin textShorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// ---- End mixins from var.scss

$s: 479px;
$m: 767px;
$l: 1023px;
$xl: 1279px;
$xxl: 1440px;

@mixin resetList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin breakpoint($point) {
  @if $point == large {
    @media only screen and (min-width: $xl) {
      @content;
    }
  }
  @else if $point == desktop {
    @media only screen and (min-width: $l) {
      @content;
    }
  }
  @else if $point == tablet {
    @media only screen and (min-width: $m) {
      @content;
    }
  }
  @else if $point == phone {
    @media only screen and (min-width: $s) {
      @content;
    }
  }
}
