@use 'css/scss/token';

.footer {
	width: 100%;
	height: 60px;
	background-color: var(--color-dominant-white);
	position: relative;
	padding: var(--dimensions-size-2) var(--dimensions-size-24);
	display: flex;
	gap: 10px;
	align-items: center;
	overflow: hidden;
	position: absolute;
    z-index: token.z(header);
    bottom: 0;
	ul {
		display: flex;
	}
	.logo {
		flex-grow: 1;
		display: flex;
    	align-items: center;
		img {
			width: 118px;
		}
	}
	.item {
		padding: 0;
		font-size: 14px;
		color: var(--color-dominant-on-dominant);
		a {
			margin: 0 var(--spacing-spacing-xx-small);
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.localesSwitcher {
	  margin-left: var(--spacing-spacing-xx-small);
	  & :global(.locales-switcher) {
		background-color: transparent;
		& ul {
		  display: flex;
		  grid-gap: var(--spacing-spacing-xxx-small);
		  list-style: none;
		  & li {
			&:hover {
			  background-color: transparent !important;
			}
		  }
		  & svg {
			width: 24px;
			border-radius: var(--border-radius-small);
			cursor: pointer;
		  }
	    }
	  }
	}
}
