.filepond {
    &.asButton {
        :global(.filepond--drop-label) {
            cursor: pointer;
            background: var(--color-dominant-white);
            border: var(--border-thin);

            min-height: var(--size-sizing-large);
            margin-bottom: 8px;
            font-size: var(--font-size-font-size-4);
            border-radius: var(--border-radius-medium);

            label {
                cursor: pointer;
            }

            &:hover {
                background: var(--color-primary-300, #86FFBD);
            }
        }
    }

    :global(.filepond--panel-root) {
        // background-color: transparent;
    }

    :global(.filepond--list-scroller) {
        top: 10px;
    }
    :global(.filepond--item) {
        // width: calc(50% - 0.5em);
    }
    :global(.filepond--credits) {
        display: none;
    }
    :global(.filepond--drip) {
        background: var(--color-tertiary-025);
        opacity: 1;
    }
    :global(.filepond--drip-blob) {
        background-color: var(--color-primary-300);
    }
}