@use "css/scss/typography";

.container {
  color: var(--color-dominant-on-dominant);
  position: relative;
  min-height: 300px;
  min-width: 340px;
  display: flex;
  flex-direction: column;

  p {
    @include typography.title-tile-subtitle;
    color: var(--color-dominant-tertiary);
  }

  >form, .wrapper, .divider {
    margin-top: var(--spacing-spacing-xx-small);
  }

  .divider {
    margin-bottom: var(--spacing-spacing-xx-small);
    color: var(--color-dominant-tertiary);
    font-size: var(--font-size-font-size-4);
    font-weight: 500;
    font-family: var(--font-family);
  }

  a:hover {
    text-decoration: underline;
  }

  .fields {
    display: grid;
    grid-gap:var(--spacing-spacing-xx-small);
    width: 100%;
    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap:var(--spacing-spacing-xx-small);
    }
  }

  .signUpLink {
    color: var(--color-dominant-on-dominant);
  }

  .askIfPasswordForgotten, .askIfWantsToLogin {
    color: var(--color-dominant-tertiary);
    @include typography.title-tile-subtitle;
    text-align: right;
  }

  .checkbox {
    height: auto;
    align-items: flex-start;
    margin-top: var(--size-sizing-xx-small);

    label {
      font-size: var(--font-size-font-size-1);
      font-weight: 400;

      &:first-of-type {
        margin-top: 2px !important;
      }
    }

    .labelClassName {
      line-height: var(--line-height-text);
      padding-top: 0;
      margin-top: 0;
      white-space: break-spaces;
    }

    &::after {
      color: var(--color-dominant-primary) !important;
      font-size: var(--font-size-font-size-4);
    }
  }

  .select, .select > div, .select button {
    width: 100% !important;
    flex: 0 auto !important;
  }

  .required {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    padding: 4px 0 0 0;
    color: #f93434;
    font-size: var(--font-size-font-size-1);
    text-align: left;

    >span[class^="Icon"] {
      margin-right: var(--size-sizing-xxx-small);
    }
  }

  iframe {
    min-width: 420px;
  }
}

.successMessage {
  display: flex;
  align-items: center;
  > span {
    margin-right: var(--size-sizing-xxx-small);
  }
}

.registerAzureButton {
  margin-top: 16px;
  align-self: center;
}