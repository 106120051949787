@use '../styles/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: visible;
  z-index: 0;

  &.fullWidth {
    width: 100%;

    .content {
      width: 100%;
    }

    .input {
      width: 100%;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    height: var(--size-sizing-medium);

    .mask {
      position: absolute;
      display: flex;
      align-items: center;
      width: fit-content;
      top: 0;
      bottom: 0;
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 400;
      color: var(--color-dominant-tertiary);
      z-index: 1;
      white-space: pre;

      &.prefix {
        left: 16px;
      }
      &.suffix {
        right: 16px;
      }
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--size-sizing-medium);
    padding: 0 48px 0 var(--spacing-spacing-xx-small);
    border-radius: var(--border-radius-medium);
    background-clip: padding-box;
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dominant-on-dominant);
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: border-color 120ms var(--transition-bezier), box-shadow 120ms var(--transition-bezier), background-color .3s ease;
    z-index: 0;

    &:hover {
      outline: 0;
    }

    &:focus {
      border-color: var(--color-dominant-tertiary);
      outline: 0;
    }

    &::placeholder {
      color: var(--color-tertiary-300);
      font-size: var(--font-size-font-size-3);
      font-style: normal;
      font-weight: var(--font-weight-small);
      opacity: 1;
      vertical-align: middle;
    }

    &:disabled,
    &:disabled + label {
      opacity: var(--opacity-disabled);
      cursor: not-allowed;
    }
  }

  .icon {
    position: absolute;
    right: 8px;
    z-index: 1;
    &.loading {
      animation: rotating 1.5s linear infinite;
    }
  }

  .searchIcon {
    position: absolute;
    left: 16px;
    z-index: 9;
    &.secondary {
      left: 0;
    }
  }

  // Sizing
  &.small {
    &.withNumberCaracs {
      min-height: 40px;
    }
    .content {
      height: var(--size-sizing-small);
    }

    .input {
      padding: 0 var(--spacing-spacing-xx-small);
      height: var(--size-sizing-small);
      border-radius: var(--border-radius-small);
    }

    .icon {
      right: 10px;
    }
  }

  &.medium {
    &.withNumberCaracs {
      min-height: 45px;
    }
  }

  &.large {
    &.withNumberCaracs {
      min-height: 50px;
    }
    .content{
      height: var(--size-sizing-large);

      .mask {
        font-size: 16px;
      }
    }

    .input {
      padding: 0 72px 0 var(--spacing-spacing-x-small);
      height: var(--size-sizing-large);
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
      }
    }
    .icon {
      right: 16px;
    }
  }

  //Theme
  &.primary {
    input {
      background-color: var(--color-white);
      border: var(--border-thin);
      &:hover {
        background-color: var(--color-primary-300);
      }
    }
  }
  &.secondary {
    .input {
      border-width: 0 0 2px 0;
      border-radius: 0;
    }
    &:hover {
      input {
        border-color: var(--color-primary-300);
      }
    }
    &.search .input{
      padding-left: 26px;
    }
  }
  &.tertiary {
    .input {
      border: none;
      background-color: var(--color-tertiary-050);
      color: var(--color-dominant-on-dominant);

      &:hover {
        border: none;
        background-color: var(--color-tertiary-100);
      }

      &.filled,
      &:active,
      &:focus {
        border: var(--border-sizing-small) solid var(--color-dominant-on-dominant);
        background-color: var(--color-tertiary-050);
      }
    }
  }
  &.tone {
    .input {
      border: none;
      background-color: var(--color-tertiary-090);
      color: var(--color-dominant-on-dominant);

      &:hover {
        border: none;
        background-color: var(--color-primary-300);
      }

      &.filled,
      &:active,
      &:focus {
        border: var(--border-sizing-small) solid var(--color-dominant-on-dominant);
        background-color: var(--color-tertiary-090);
      }
      &::placeholder {
        color: var(--color-tertiary-500);
      }
    }
  }

  &.search .input {
    padding-left: 42px;
  }

  .nbCaracters {
    align-self: flex-end;
    margin-top: 4px;
    font-size: 12px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
  }

  .helper {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    color: var(--color-tertiary-300);
    font-size: 12px;
    text-align: left;
    .wrapper {
      margin: 4px 0 0 12px;
      display: flex;
      align-items: center;
      grid-gap: var(--size-sizing-xxx-small);
      .message {
        display: block;
        width: 100%;
      }
    }

  }

  &.error {
    .input {
      border-color: var(--color-alert-error) !important;
      &.tone {
        background-color: var(--color-tertiary-040);
      }
    }
    .helper {
      .message * {
        color: var(--color-alert-error);
      }
    }
    svg {
      fill: var(--color-alert-error) !important;
    }
  }

  &.reset {
    flex-grow: 2;
    height: initial !important;

    .content {
      width: 100%;
    }

    .input {
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      font-size: 13px;
      &:hover {
        background-color: transparent;
      }

      &:focus {
        @include mixins.resetBorderFocus;
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
