
.container {
  position: relative;
  overflow: hidden;
  will-change: height;

  .wrapper {
    position: relative;
    padding: 0;
    overflow: hidden;

    & > * {
      flex-shrink: 0;
    }
  }
}

