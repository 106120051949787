

.radios {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-spacing-xxx-small);
  > div {
    margin: 0 !important;
    width: 100%;
  }
}