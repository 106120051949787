
.radios {
  display: block;

  .title {
    margin: 0 0 var(--spacing-spacing-xxx-small) 0;
    color: var(--color-dominant-tertiary);
    font-weight: 500;
    font-size: 16px;
  }

  &.horizontal {
    .radio:not(:last-child) {
      margin-right: var(--spacing-spacing-xx-small);
    }
  }

  &.vertical {
    display: flex;
    flex-flow: column wrap;

    .radio:not(:last-child) {
      margin-bottom: var(--spacing-spacing-xx-small);
    }
  }
}


