
.text {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-dominant-on-dominant);

  &:not(:last-child) {
    margin-bottom: var(--spacing-spacing-xx-small);
  }

  &.span {
    display: inline-flex;
    margin-bottom: 0;
  }

  &.small {
    font-size: 14px;
    line-height: 21px;
  }

  &.big {
    font-size: 18px;
    line-height: 27px;
  }

  &.reset {
    margin: 0 !important;
  }

  &.fontTitle {
    font-family: var(--font-family-heading);
  }
  &.fontSubtitle {
    font-family: var(--font-family-var-text);
    letter-spacing: 0.32px;
  }
  &.fontVariable {
    font-family: var(--font-family-var);
    letter-spacing: 0.32px;
  }
}

.h1, .h2, .h3, .h4, .h5 {
  margin: 0 0 12px 0;
  line-height: 1.5;
}

.h1 {
  font-size: 38px;
}

.h2 {
  font-size: 32px;
}

.h3 {
  font-size: 28px;
  font-weight: 500;
}

.h4 {
  font-size: 24px;
  font-weight: 500;
}

.h5 {
  font-size: 18px;
  font-weight: 500;
}

