@import '~flatpickr/dist/themes/airbnb.css';

.flatpickr-calendar {
    background-color: var(--color-dominant-white);
    border: var(--border-thin);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--menu-shadow);

    padding: var(--spacing-spacing-x-small);
    width: auto !important;

    &.arrowTop::before {
        border-bottom-color: var(--color-dominant-tertiary);
    }

    &.arrowBottom::before {
        border-top-color: var(--color-dominant-tertiary);
    }

    &::after {
        border-width: 2px;
        margin: 0 -2px;
    }
}

.flatpickr-months {
    position: relative;

    .flatpickr-prev-month,
    .flatpickr-next-month {

        svg,
        svg:hover {
            fill: var(--color-dominant-tertiary) !important;
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 204, 255, 0.1);
            border-radius: 100%;
            transform: scale(0);
            transition: transform 100ms linear;
            z-index: 1;
        }

        &:hover::before {
            transform: scale(1);
        }
    }
}

.flatpickr-current-month,
.flatpickr-current-month span.cur-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
    font-size: var(--font-size-font-size-5);
    color: var(--color-dominant-tertiary);
    font-weight: var(--font-weight-regular);
}

.flatpickr-weekdays {
    height: 40px;

    .flatpickr-weekday {
        color: var(--color-tertiary-400);
        font-size: var(--font-size-font-size-3);
        font-weight: var(--font-weight-regular);
    }
}

.flatpickr-days,.flatpickr-weekdays {
    gap: var(--spacing-spacing-xx-small);
    width: 100% !important;
}

.flatpickr-days>.dayContainer,.flatpickr-weekdays>.flatpickr-weekdaycontainer {
        justify-content: flex-start;
        width: auto;
        width: 280px;
        min-width: 280px;
        max-width: 280px;
}

.dayContainer + .dayContainer {
    box-shadow: none;
}

span.flatpickr-day {
    height: 40px;
    width: 40px;
    margin-bottom: var(--spacing-spacing-thin);

    color: var(--color-dominant-on-dominant);
    font-weight: var(--font-weight-medium);
    border: none !important;

    &:not(.today) {
        border: 0;
    }

    &:hover {
        background-color: var(--color-primary-050);
    }

    &.selected,
    &.startRange,
    &.endRange {
        background-color: var(--color-dominant-secondary) !important;
        box-shadow: none !important;
    }

    &.selected {
        border-radius: 50% !important;
        color: var(--color-dominant-tertiary);
    }

    &.startRange,
    &.inRange:nth-of-type(7n-6) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }

    &.endRange,
    &.inRange:nth-of-type(7n+7) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }

    &.today {
        color: var(--color-dominant-secondary);
        font-weight: var(--font-weight-medium);

        &:hover {
            background-color: var(--color-primary-050) !important;
            border: 0;
            color: var(--color-dominant-secondary);
        }
        &.selected {
            color: var(--color-dominant-tertiary);
        }
    }

    &.inRange {
        box-shadow: none !important;
        background-color: var(--color-primary-050) !important;

        &~.hidden+:not(.hidden) {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
        }
        &:not(.hidden):has(+ .hidden) {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
        }
    }

    &.hidden {
        display: block !important;
    }

    &.previousDate {
        color: var(--color-dominant-on-dominant);
    }
}