/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

$hugeIcon: 24px;
$bigIcon: 16px;
$defaultIcon: 12px;

.button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: var(--font-family);
  color: var(--color-dominant-white);
  font-weight: 500;
  line-height: 1;
  background-color: var(--color-dominant-primary);
  border: none;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    width: calc(100% - 18px);
    opacity: 1;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    white-space: nowrap;
    -webkit-transition: opacity 200ms var(--transition-bezier);
    -o-transition: opacity 200ms var(--transition-bezier);
    transition: opacity 200ms var(--transition-bezier);
  }

  &.fullWidth {
    width: 100% !important;
  }

  &.isLoading .content {
    opacity: 0;
  }

  &:hover {
    background-color: var(--color-primary-700);
  }

  &.default .icon svg {
    fill: var(--color-dominant-white) !important;
  }

  &.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;

    .icon {
      margin: 0 0 0 var(--spacing-spacing-xxx-small);
    }
  }

  .icon {
    width: $defaultIcon;
    margin: 0 var(--spacing-spacing-xxx-small) 0 0;

    &.disabled svg {
      fill: #7F97B0 !important;
    }
  }

  &.huge {
    padding: 0 24px;
    height: 56px;
    font-size: 24px;
    font-weight: 700;

    .icon {
      width: $hugeIcon !important;
      margin: 0 var(--spacing-spacing-xx-small) 0 0;

      svg {
        width: $hugeIcon !important;
        height: $hugeIcon !important;
      }
    }

    &.reverse .icon {
      margin: 0 0 0 var(--spacing-spacing-xx-small);
    }
  }

  &.big, &.fat {
    padding: 0 var(--spacing-spacing-xx-small);
    height: 40px;
    font-size: 16px;

    .icon {
      width: $bigIcon;
      margin: 0 12px 0 0;

      svg {
        width: $bigIcon !important;
        height: $bigIcon !important;
      }
    }

    &.reverse .icon {
      margin: 0 0 0 12px;
    }
  }

  &.fat {
    height: 48px;
  }

  &.medium {
    padding: 0 12px;
    height: 32px;
    font-size: 12px;

    .icon {
      width: $defaultIcon !important;

      svg {
        width: $defaultIcon !important;
        height: $defaultIcon !important;
      }
    }
  }

  &.small {
    padding: 0 12px;
    height: 24px;
    font-size: 11px;

    .icon {
      width: $defaultIcon !important;

      svg {
        width: $defaultIcon !important;
        height: $defaultIcon !important;
      }
    }
  }

  &.blue {
    background-color: var(--color-dominant-secondary);
  }

  &.navy {
    background-color: var(--color-dominant-white);
    border: var(--border-thin);
    color: var(--color-dominant-tertiary);

    &:hover {
      background-color: var(--color-tertiary-025);
      border-color: var(--color-tertiary-100);
    }

    &:focus {
      outline: none;
      -webkit-box-shadow: 0 0 0 3px var(--color-tertiary-025);
      box-shadow: 0 0 0 3px var(--color-tertiary-025),
    }
  }

  &.ghost, &.reset, &.light {
    padding: 4px var(--spacing-spacing-xxx-small);
    background-color: var(--color-dominant-white);
    border: none;
    color: var(--color-dominant-tertiary);

    &:hover {
      background-color: var(--color-tertiary-025);
      border-color: var(--color-tertiary-100);
    }
  }

  &.reset:hover {
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:disabled {
    color: var(--color-tertiary-300);
    border-color: var(--opacity-disabled);
    background-color: var(--opacity-disabled);

    &:hover {
      border-color: var(--opacity-disabled);
      background-color: var(--opacity-disabled);
    }

    .icon {
      opacity: .5;
      svg {
        fill: #7F97B0 !important;;
      }
    }
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 3px var(--color-navy-100),;
    box-shadow: 0 0 0 3px var(--color-navy-100),
  }

  &.onlyIcon {
    background-color: transparent;

    &.small {
      padding: 0 var(--spacing-spacing-xxx-small);
    }

    &:hover {
      border-color: var(--opacity-disabled);
      background-color: transparent;
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .icon {
      margin: 0;
      svg {
        fill: var(--color-dominant-tertiary) !important;;

      }
    }

    &:disabled .icon:hover {
      background-color: transparent;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    span {
      width: 8px;
      height: 8px;
    }
  }

  &.withIcon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 12px;

    .content {
      padding: 0 2px 0 0;
      white-space: nowrap;
    }

    &.reverse {
      padding: 0 12px;
      .content {
        padding: 0 0 0 2px;
      }
    }

    &.big {
      width: auto;
    }
  }
}

.spanWrapper {
  display: inline-block;
  width: fit-content;
}
