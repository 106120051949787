

.container {
    z-index: 0;
    position: relative;
    &.fullWidth {
        width: 100%;
    }

    &.range {
        display: flex;
        gap: var(--spacing-spacing-xxx-small);
    }
}

.label {
    font-size: var(--font-size-font-size-3);
}

.input {
    &.range-condensed {
        input:not([type="hidden"]) {
            min-width: 250px;
        }
    }

    input:not([type="hidden"]) {
        padding: 0 var(--spacing-spacing-xx-small);

        &:not(:focus) {
            cursor: pointer;
        }
    }
}