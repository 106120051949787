/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.divider {
  width: 100%;
  height: 1px;
  margin: 28px 0;
  background: rgba(0,48,87,0.2);

  &.small {
    margin: 14px 0;
  }
  &.noMargin {
    margin: 0;
  }
}
