@use '../styles/mixins.scss';

.root {
  z-index: 1000000 !important;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;

  .label {
    margin-right: var(--spacing-spacing-xxx-small);
    flex: 0 1 auto;
    white-space: pre;
    @include mixins.header-row-title;

    &.small {
      font-size: var(--font-size-font-size-3);
    }
  }

  &.labelTop {
    flex-direction: column;
    align-items: flex-start;

    .label {
      margin-right: 0;
      margin-bottom: var(--spacing-spacing-xxx-small);
    }
  }

  &.fullWidth {
    width: 100% !important;

    > div, button {
      width: 100% !important;
    }
  }
}

.button {
  > span {
    justify-content: flex-start;
    color: var(--color-secondary-900);

    &:first-child {
      margin-left: var(--spacing-spacing-x-small) !important;
    }
  }

  &.isPlaceholder {
    span {
      color: var(--color-tertiary-300);
    }
  }

  .icon {
    width: 15px !important;
    flex: 0 0 15px!important;
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }

  .clear > svg:hover {
    fill: var(--color-dominant-tertiary) !important;
  }
}

.input {
  margin-bottom: var(--spacing-spacing-xxx-small);
  padding: 0 var(--spacing-spacing-xxx-small);

  &:focus {
    outline: 0;
  }
}

.items {
  position: relative;
  overflow-y: auto;
}

.item {
  display: flex;
  width: 100%;
  padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xx-small);
  list-style-type: none;
  box-sizing: border-box;
  font-size: var(--font-size-font-size-3);
  font-weight: 400;
  color: var(--color-dominant-on-dominant);

  &.selected {
    background-color: var(--color-item-selected);
  }

  &:hover,
  &:focus {
    background: var(--color-item-hovered);
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.noResultTitle {
  cursor: default !important;
}

:global(#kollab_select_collapse_medium) {
  :global(.MuiPaper-root) {
    border-radius: 8px !important;
  }
}

:global(#kollab_select_collapse_small) {
  :global(.MuiPaper-root) {
    border-radius: 4px !important;
  }
}

.paper {
  border: var(--border-sizing-small) solid var(--color-dominant-on-dominant) !important;
}