.image {
  width: 108px;
  height: 108px;
  background-color: var(--color-tertiary-025);
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hasQrCode {
    width: 168px;
    height: 168px;
  }
  img.isImg {
    width: 100%;
    height: 100%;
  }
  .loading > div {
    padding: 0;
  }
}

.step {
  background-color: var(--color-dominant-secondary);
  border-radius: 30px;
  padding: 0 var(--spacing-spacing-xx-small);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
