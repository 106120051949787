

.titleContainer {
    display: flex;
    align-items: center;
    &Icon {
        margin-right: var(--spacing-spacing-xxx-small);
    }
}

.newVersionRequired {
	&Paragraph {
		padding: 0;
		font-size: 16px;
		color: #003057;
    }
    &Subbody {
        display: block;
        margin-top: var(--spacing-spacing-xx-small);
        opacity: 0.6;
        font-size: 14px;
    }
}
