.errorMessage {
  margin-top: var(--spacing-spacing-xxx-small);
  padding: var(--spacing-spacing-xxx-small);
  border: 2px solid #f44336;
  border-radius: var(--border-radius-small);
  background-color: #ffebee;

  .error {
    color: #ba000d;
    line-height: 1.5;
  }
}
