@use '../styles/mixins';

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  overflow: visible;
}

.label {
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--spacing-spacing-xxx-small);
  @include mixins.header-row-title;
  pointer-events: none;

  &.required:after {
    content: '*';
    display: inline-block;
    padding-left: 4px;
    color: var(--color-dominant-primary);
  }

  .icon {
    margin-right: 4px;
  }
}
