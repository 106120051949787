/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.loader {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  padding: var(--spacing-spacing-xx-small);
  z-index: 2147483647;

  &.full {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-dominant-white);

    &.withTitle {
      flex-direction: column;
    }
  }

  &.fullWindow {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-dominant-white);
  }

  &.shadow {
    box-shadow: var(--float-shadow);
  }

  .title {
    color: var(--color-dominant-tertiary);
    font-family: var(--font-family-var);
    font-size: 32px;
  }
}

.container {
  position: relative;
  display: flex;
  height: 100%;
  padding: var(--spacing-spacing-small);

  &.withTitle {
    height: initial;
  }

  span {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: var(--color-dominant-tertiary);
    border-radius: 100%;

    &:nth-child(1) {
      -webkit-animation: wave_bounce_loader 1000ms ease-out infinite;
      animation: wave_bounce_loader 1000ms ease-out infinite;
    }
    &:nth-child(2) {
      margin-left: 3px;
      margin-right: 3px;
      -webkit-animation: wave_bounce_loader 1000ms ease-out infinite 0.1s;
      animation: wave_bounce_loader 1000ms ease-out infinite 0.1s;
    }
    &:nth-child(3) {
      -webkit-animation: wave_bounce_loader 1000ms ease-out infinite 0.2s;
      animation: wave_bounce_loader 1000ms ease-out infinite 0.2s;
    }
  }

  &.light {
    span {
      background-color: var(--color-dominant-white);
    }
  }

  &.navy {
    span {
      background-color: var(--color-dominant-tertiary);
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.small {
    span {
      width: 8px;
      height: 8px;
    }
  }
}

@-webkit-keyframes wave_bounce_loader {
  0% { -webkit-transform: translateY(0%); transform: translateY(0%); }
  12% { -webkit-transform: translateY(-50%); transform: translateY(-50%); }
  25% { -webkit-transform: translateY(50%); transform: translateY(50%); }
  50% { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@keyframes wave_bounce_loader {
  0% { -webkit-transform: translateY(0%); transform: translateY(0%); }
  12% { -webkit-transform: translateY(-50%); transform: translateY(-50%); }
  25% { -webkit-transform: translateY(50%); transform: translateY(50%); }
  50% { -webkit-transform: translateY(0%); transform: translateY(0%); }
}
