
.page {
  display: grid;
  flex: 1;
  grid-template-rows: 1fr auto;
  position: relative;
}

@mixin background {
  background: var(--color-gradiant-300);
  & > div {
    z-index: 0;
  }
  &::after {
    content: '';
    background-image: url("https://static.kolsquare.com/assets/images/patterns/Pattern_01.svg");
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
    background-size: 232px;
    filter: blur(12px);
  }
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include background;
}

.footer {
  width: 100%;
  height: 60px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content {
  width: 100%;
  max-width: 580px;
  border-radius: 12px;
  background-color: white;
  z-index: 1;
  box-shadow: var(--float-shadow);

  >.paddedContent {
    padding: var(--spacing-spacing-medium);
  }

  &.large {
    max-width: 669px;
  }
}
