/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/


.li {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--spacing-spacing-thin) var(--spacing-spacing-xxx-small);
  color: var(--color-dominant-on-dominant);
  font-family: var(--font-family);
  font-size: var(--font-size-font-size-3);
  font-weight: 500;
  line-height: var(--line-height-text);
  vertical-align: middle;
  -webkit-transition: background-color var(--transition-hover);
  -o-transition: background-color var(--transition-hover);
  transition: background-color var(--transition-hover);

  &.small {
    padding: var(--spacing-spacing-thin) var(--spacing-spacing-xxx-small);
  }

  &.medium {
    padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xxx-small);
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }

    .icon svg  {
      fill: var(--color-tertiary-300);;
    }
  }

  &.button {
    cursor: pointer;
  }

  .icon {
    margin-right: 8px;
    z-index: 9;
  }

  .notification {
    position: absolute;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    transform: translate(0, -100%);

    .contentNotification {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 18px;
      height: 18px;
      font-size: 9.5px;
      font-weight: 600;
      line-height: 0;
      color: var(--color-dominant-white);
      text-align: center;
      background-color: var(--color-dominant-secondary);
      border-radius: 100%;
      will-change: transform;

      &.bigNotif {
        min-width: 20px;
        height: 20px;
        font-size: 10px;
      }

      &.withoutNotifNumber {
        margin-left: -12px;
        min-width: 12px;
        width: 12px;
        height: 12px;
        color: var(--color-dominant-secondary);
        font-size: 1px;

        &.bigNotif {
          width: 20px;
        }
      }

      &.animated {
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
    }
  }

  .react {
    margin-right: var(--spacing-spacing-xxx-small);
  }
}

@keyframes bounceIn {
  0% {
    -webkit-transform: scale3d(1, 1, 1) translate(0, 0);
    transform: scale3d(1, 1, 1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3) translate(0, 0);
    transform: scale3d(1.3, 1.3, 1.3) translate(0, 0);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) translate(5%, 0);
    transform: scale3d(1, 1, 1) translate(0, 0);
  }
}
