.span {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  margin: 0;
  overflow: hidden;
  // line-height: normal;

  .child {
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    white-space: nowrap;
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.whiteText,
.whiteText>* {
  color: var(--color-dominant-white) !important;

  /* tooltip/text */
  font-family: Nunito !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important; /* 21px */
}