

.navbar {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  width: 64px;
  padding-bottom: var(--spacing-spacing-xx-small);
  background-color: var(--color-dominant-white);
  border-right: 1px solid var(--color-tertiary-100);

  .logo {
    height: var(--size-sizing-xx-large);
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    margin-bottom: var(--spacing-spacing-large);

    img {
      width: 28px;
    }
  }

  .menu {
    flex-grow: 2;

    .item {
      display: block;
      margin-bottom: var(--spacing-spacing-xx-small);
    }
    :global(.active) {
      svg {
        fill: var(--color-primary-300) !important
      }
    }
  }

  .createCampaign {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 64px;
    left: calc(-50vw + 50%);
    padding-bottom: var(--spacing-spacing-xx-small);
    border-bottom: 1px solid var(--color-tertiary-100);

    > a { margin-bottom: var(--spacing-spacing-xx-small) }
  }

  .bottom {
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    > * {
      margin-top: var(--spacing-spacing-xx-small);
    }
  }
}

@media only screen and (max-height: 640px) {
  .navbar {
    .logo {
      margin-bottom: var(--spacing-spacing-xx-small);
    }
    .menu {
      .item {
        margin-bottom: var(--spacing-spacing-xxx-small);
      }
    }

    .bottom {
      > * {
        margin-top: var(--spacing-spacing-xxx-small);
      }
    }
  }
}