/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/


.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  overflow: visible;
}

.label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: var(--spacing-spacing-thin);
  font-size: var(--font-size-font-size-4);
  color: var(--color-dominant-on-dominant);
  font-weight: 400;
  line-height: var(--line-height-var-text);
  pointer-events: none;

  &.required:after {
    content: '*';
    display: inline-block;
    padding-left: var(--spacing-spacing-thin);
    color: var(--color-dominant-primary);
  }

  .icon {
    margin-right: var(--spacing-spacing-thin);
  }
}
