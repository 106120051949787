.tile {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-spacing-xx-small);
  gap: var(--spacing-spacing-xx-small);
  align-self: stretch;
  border-radius: var(--border-radius-medium);
  border: var(--border-thin);
}

.tertiary {
  color: var(--color-dominant-tertiary);
}

.sna {
  &:first-letter {
    text-transform: uppercase;
  }
}

.hiddenLabel {
  opacity: 0;
  visibility: hidden;
}

.country {
  display: grid;
  grid-gap: var(--spacing-spacing-xxx-small);
  align-items: center;
  grid-template-columns: 1fr 164px 32px;
  &.hasOR {
    grid-template-columns: 20px 1fr 164px 32px;
  }
}
