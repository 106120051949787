

.paper {
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-tertiary-100, #BECDDC);
  background: var(--color-dominant-white);
  filter: drop-shadow(2px 2px 4px rgba(0,48,87,0.2));

  .list {
    padding: var(--size-sizing-thin) 0;
    width: 200px;
  }
}

.dropdown {
  :global(.dropdown-items) {
    width: fit-content;
  }
}

.actionLink {
  display: flex;
  flex-flow: column wrap;
  padding: 4px 0;

  a, button, span {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    color: var(--color-dominant-on-dominant);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-050);
    }

    .label {
      margin: 0 4px 0 8px;
    }
  }

  .item {
    padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xx-small);

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
