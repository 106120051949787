.root {
    height: var(--size-sizing-xx-large);
    width: 100%;

    position: relative;
    display: flex;
    align-items: center;
    padding-left: var(--spacing-spacing-xx-small);

    font-size: var(--font-size-font-size-3);
    font-weight: var(--font-weight-medium);
    color: var(--color-dominant-on-dominant);
    line-height: var(--line-height-var-text);

    .icon {
        margin-right: var(--spacing-spacing-xxx-small);
    }

    .message {
        flex: 1;
    }

    a {
        text-decoration: underline;
    }
}

.info {
    background-color: var(--color-alert-information);
}

.warning {
    background-color: var(--color-alert-warning);
}

.announcement {
    background-color: var(--color-alert-new);
}
