body {
    #iubenda-cs-banner {
        button.iubenda-cs-accept-btn.iubenda-cs-btn-primary {
            color: var(--color-dominant-on-dominant) !important;
            background: linear-gradient(103deg, var(--startColor) -13.82%, var(--endColor) 121.29%) !important;
            --startColor: var(--color-green-300);
            --endColor: var(--color-blue-300);
            transition: --startColor 0.2s, --endColor 0.2s;

            &:hover {
                --endColor: var(--color-green-300);
            }
        }
        button.iubenda-cs-reject-btn.iubenda-cs-btn-primary {
            color: var(--color-dominant-on-dominant) !important;
            background: transparent !important;
            &:hover {
              background: var(--color-tertiary-100) !important;
              .badge {
                background: var(--color-tertiary-040) !important;
              }
            }
        }
    }
}