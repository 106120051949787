@use 'css/scss/token';

.wrapper {
  position: relative;
  &.fullWidth {
    width: 100%;
  }
}
.popper {
  z-index: token.z(portal);
}
.optionsList {
  z-index: 1;
  list-style: none;
  background-color: var(--color-dominant-white);
  border: var(--border-thin);
  border-radius: var(--border-radius-small);
  box-shadow: var(--menu-shadow);
  overflow: auto;
  padding: var(--size-sizing-xxx-small) 0;
  margin: var(--spacing-spacing-thin) 0;
  max-height: 250px;
}

.option {
  color: var(--color-dominant-tertiary);
  font-size: var(--font-size-font-size-3);
  padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xx-small);
  cursor: pointer;
  &:hover {
    background-color: var(--color-item-hovered);
  }
  &Error {
    color: var(--color-alert-error);
    font-weight: 500;
  }
}
.hint {
  padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xx-small);
  color: var(--color-dominant-tertiary);
  font-size: var(--font-size-font-size-3);
  font-weight: var(--font-weight-regular);
}
