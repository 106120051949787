.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-spacing-xxx-small);

  >.row {
    display: flex;
    gap: var(--spacing-spacing-xxx-small);
    align-items: center;

    .mainSelect, .mainInput {
      flex: 1;
    }

    .percentageSelect {
      flex: 0 0 110px;
    }

    .icon {
      visibility: hidden;
    }

    &:hover>.icon {
      visibility: visible;
    }

    &:not(:has(>.icon)) {
      padding-right: var(--spacing-spacing-medium);

      &:not(:has(>.percentageSelect)) {
        padding-right: var(--spacing-spacing-medium);
      }
    }
  }
}