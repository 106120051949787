@keyframes bounceIn {
  0% {
    transform: scale3d(1, 1, 1) translate(0, 0);
  }
  50% {
    transform: scale3d(1.3, 1.3, 1.3) translate(0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1) translate(0, 0);
  }
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  &.isButton {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary-100);
      border-radius: 50%;
      transform: scale(0);
      transition: transform 100ms ease-in;

    }

    &:hover:before, &.hovered:before {
      transform: scale(1);
      z-index: -1;
    }
  }

  svg {
    display: inline-flex;
    flex-grow: 1;
    vertical-align: middle;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .7;
    &:hover:before {
      transform: scale(0);
    }
  }

  &.alignComment {
    svg {
      margin-top: 2px;
    }
  }

  &.alignTag {
    svg {
      margin: 2px 0 0 2px;
    }
  }
}

.iconBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.iconNavbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.navIcon {
  opacity: 1;
  transition: opacity 150ms linear;

  &.off {
    opacity: 0;
  }
}

.hoveredIcon {
  position: absolute;
  transform: scale(0);
  transition: transform 100ms linear;

  svg {
    fill: var(--color-primary-300) !important
  }

  &.active {
    transform: scale(1);

    svg {
      fill: var(--color-primary-300) !important;
      path:nth-of-type(1) {
        fill: url(#gradient);
      }
      path:nth-of-type(2) {
        fill: var(--color-dominant-on-dominant);
      }
    }
  }
}

.notification {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 0;
  transform: translate(0, -100%);
  z-index: 2;

  .contentNotification {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    height: 18px;
    font-size: 9.5px;
    font-weight: 600;
    line-height: 0;
    color: var(--color-dominant-white);
    text-align: center;
    background-color: var(--color-primary-700);
    border-radius: 100%;
    will-change: transform;

    &.bigNotif {
      min-width: 20px;
      height: 20px;
      font-size: 10px;
    }

    &.withoutNotifNumber {
      margin-left: -12px;
      min-width: 12px;
      width: 12px;
      height: 12px;
      color: var(--color-dominant-secondary);
      font-size: 1px;

      &.bigNotif {
        width: 20px;
      }
    }

    &.animated {
      animation-name: bounceIn;
      animation-duration: .3s;
      animation-fill-mode: both;
    }
  }
}
