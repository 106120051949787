/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

$smallHeight: 24px;
$defaultHeight: 32px;
$bigHeight: 48px;
$hugeHeight: 56px;

@use '../styles/mixins';

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: visible;

  &.fullWidth {
    width: 100%;

    .content {
      width: 100%;
    }

    .input {
      width: 100%;
    }
  }

  .content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: $defaultHeight;
  }

  .input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: $defaultHeight;
    padding: 0 12px;
    border: var(--border-thin);
    border-radius: var(--border-radius-small);
    background-color: var(--color-dominant-white);
    background-clip: padding-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
    color: var(--color-dominant-tertiary);
    line-height: 1;
    -webkit-transition: border-color 120ms var(--transition-bezier), -webkit-box-shadow 120ms var(--transition-bezier);
    transition: border-color 120ms var(--transition-bezier), -webkit-box-shadow 120ms var(--transition-bezier);
    -o-transition: border-color 120ms var(--transition-bezier), box-shadow 120ms var(--transition-bezier);
    transition: border-color 120ms var(--transition-bezier), box-shadow 120ms var(--transition-bezier);
    transition: border-color 120ms var(--transition-bezier), box-shadow 120ms var(--transition-bezier), -webkit-box-shadow 120ms var(--transition-bezier);

    &:focus {
      @include mixins.borderFocus;
    }

    &::-webkit-input-placeholder {
      color: var(--color-tertiary-300);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }

    &::-moz-placeholder {
      color: var(--color-tertiary-300);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      transform: translateY(-1px);
    }

    &:-ms-input-placeholder {
      color: var(--color-tertiary-300);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
    }

    &::-ms-input-placeholder {
      color: var(--color-tertiary-300);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
    }

    &::placeholder {
      color: var(--color-tertiary-300);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
    }

    &:disabled,
    &:disabled + label {
      background-color: var(--opacity-disabled);
      cursor: not-allowed;
    }
  }

  &.big {
    .content{
      height: $bigHeight;
    }

    input {
      height: $bigHeight;

      &::-webkit-input-placeholder {
        font-size: 13px;
      }

      &::-moz-placeholder {
        font-size: 13px;
      }

      &:-ms-input-placeholder {
        font-size: 13px;
      }

      &::-ms-input-placeholder {
        font-size: 13px;
      }

      &::placeholder {
        font-size: 13px;
      }
    }
  }

  &.small {
    .content {
      height: $smallHeight;
    }
    input {
      padding: 0 var(--spacing-spacing-xxx-small);
      height: $smallHeight;
    }
  }

  .helper {
    display: block;
    width: 100%;
    padding: 4px 0 0 12px;
    color: var(--color-tertiary-300);
    font-size: 11px;
    text-align: left;
  }

  &.error {
    .helper {
      color: var(--color-alert-error);
    }
    svg {
      fill: var(--color-alert-error) !important;
    }
  }

  .icon {
    position: absolute;
    width: 12px;
    right: 12px;
  }

  &.hasIcon input{
    padding: 0 34px 0 12px;
  }

  &.reset {
    flex-grow: 2;
    height: initial !important;

    .content {
      width: 100%;
    }

    .input {
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      font-size: 13px;

      &:focus {
        @include mixins.resetBorderFocus;
      }
    }
  }
}
