@use 'kolkit/styles/mixins';

$inputCheckboxSize: 16px;
$radioCheckedInnerShadow: 0;

.inputRadio {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: fit-content;
  height: 16px;
  vertical-align: bottom;
  overflow: hidden;

  &:hover {
    input:disabled + label {
      &:before {
        border-color: var(--color-navy-100);
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.required {
    &:after {
      content: '*';
      display: inline-block;
      color: var(--color-secondary-900);
      padding-left: var(--spacing-spacing-xxx-small);
    }
  }

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 19px;
    line-height: 16px;
    user-select: none;
    cursor: pointer;
    z-index: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: $inputCheckboxSize;
      height: $inputCheckboxSize;
      top: 0;
      left: 0;
      box-sizing: border-box;
      transition: transform 0.15s ease-out, opacity 0.15s linear,
      background-color 0.15s linear, box-shadow 0.15s ease-in;
      will-change: transform, opacity, background-color;
    }

    &:before {
      border: var(--border-medium);
    }

    &:hover:before {
      border-color: var(--color-dominant-secondary);
    }
  }

  input {
    position: absolute;
    left: -9999px;
    opacity: 0;

    &:checked + label:before {
      border-color: var(--color-dominant-secondary);
    }

    &:focus + label:before {
      box-shadow: none;
    }

    &:not(:checked) + label {
      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }

    &:disabled + label,
    &:disabled:hover + label {
      cursor: not-allowed;
      &:before {
        background-color: var(--opacity-disabled);
        border-color: var(--color-navy-100);
        cursor: not-allowed;
      }
    }
  }

  [type='radio'] {
    + label {
      &:before {
        background-color: var(--color-dominant-white);
      }

      &:before,
      &:after {
        border-radius: 100%;
      }
    }

    &:checked + label:before {
      background-color: transparent;
      box-shadow: $radioCheckedInnerShadow;
      z-index: 1;
    }

    &:focus:checked + label:before {
      box-shadow: $radioCheckedInnerShadow, var(--color-navy-100);
    }

    &:checked + label:after {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 100%;
      background-color: var(--color-dominant-secondary);
      border: 3px solid var(--color-dominant-white);
      opacity: 1;
      transform: scale(1);
    }
  }

  &.KolLab {
    overflow: visible;
    label {
      color: var(--color-dominant-on-dominant);
      &:before {
        border: var(--border-thin);
      }

      &:hover:before {
        border-color: var(--color-dominant-on-dominant);
        box-shadow: 0 0 0 5px var(--color-primary-100);
      }
    }

    input {
      &:checked + label:before {
        border-color: var(--color-dominant-on-dominant);
      }
    }

    [type='radio'] {
      &:checked + label:after {
        background-color: var(--color-primary-300);
        border: 4px solid var(--color-dominant-white);
      }
    }
  }
}

.label {
  display: flex;
  flex: 1 1;
  align-items: center;
  cursor: pointer;

  > span {
    margin-left: var(--spacing-spacing-thin);
    color: var(--color-dominant-tertiary);
    font-size: 12px;
    @include mixins.textShorten();
  }

  &:first-letter {
    text-transform: uppercase;
  }

  &.disabled span {
    color: var(--color-tertiary-300);
    cursor: not-allowed;
  }

  &.KolLab {
    > span {
      color: var(--color-dominant-on-dominant);
      font-size: 14px;
      font-weight: 500;
    }
  }
}

