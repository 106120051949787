.activatedMessage {
  background-color: var(--color-primary-100) !important;
  justify-content: center;
}

.slider {
  position: relative;
  transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  > div {
    will-change: transform, opacity;
    user-select: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  h3 {
    margin-top: 16px !important;
    line-height: 24px !important;
  }
}

.buttonBase {
  background: var(--color-tertiary-025);
  border-radius: var(--border-radius-medium);
  height: 80px;
  width: 100%;
  justify-content: space-between;
  padding: 0 var(--spacing-spacing-x-small);
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: var(--color-primary-300);
  }
}
