

.savedSearches {
    // Position
    margin-top: var(--size-sizing-small);

    // Layout
    display: flex;
    padding: var(--spacing-spacing-xx-small);
    flex-direction: column;
    gap: var(--spacing-spacing-xxx-small);
    align-content: stretch;

    // Style
    border-radius: var(--border-radius-medium);
    background: var(--color-tertiary-025);
}