@use 'kolkit/styles/mixins';

$inputCheckboxSize: 16px;
$radioCheckedInnerShadow: 0;

.inputCheck {
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 16px;
  vertical-align: bottom;

  &:hover {
    .customInput:before {
      border-color: var(--color-dominant-on-dominant);
    }

    input:disabled + label {
      &:before {
        border-color: var(--color-navy-100);
        opacity: .4;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.required {
    &:after {
      content: '*';
      display: inline-block;
      color: var(--color-dominant-on-dominant);
      padding-left: var(--spacing-spacing-xxx-small);
    }
  }

  .customInput {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 $inputCheckboxSize;
    width: $inputCheckboxSize;
    height: $inputCheckboxSize;
    cursor: pointer;
    user-select: none;
    transition: transform 150ms ease-in-out, background-color 150ms linear;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: $inputCheckboxSize;
      height: $inputCheckboxSize;
      top: 0;
      left: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: opacity 0.15s linear,
      background-color 0.15s linear, -webkit-transform 0.15s ease-out, -webkit-box-shadow 0.15s ease-in;
      transition: opacity 0.15s linear, background-color 0.15s linear, -webkit-transform 0.15s ease-out, -webkit-box-shadow 0.15s ease-in;
      -o-transition: transform 0.15s ease-out, opacity 0.15s linear,
      background-color 0.15s linear, box-shadow 0.15s ease-in;
      transition: transform 0.15s ease-out, opacity 0.15s linear, background-color 0.15s linear, box-shadow 0.15s ease-in;
      transition: transform 0.15s ease-out, opacity 0.15s linear,
      background-color 0.15s linear, box-shadow 0.15s ease-in, -webkit-transform 0.15s ease-out, -webkit-box-shadow 0.15s ease-in;
      will-change: transform, opacity, background-color;
    }

    &:before {
      border: var(--border-thin);
    }
  }

  input {
    position: absolute;
    left: -9999px;
    opacity: 0;

    &:checked + label:before {
      border-color: var(--color-dominant-on-dominant);
    }

    &:focus + label:before {
      box-shadow: none;
    }

    &:not(:checked) + label {
      .icon {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
      }

      &:after {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
      }
    }

    &:disabled + label,
    &:disabled:hover + label {
      cursor: not-allowed;
      &:before {
        background-color: var(--opacity-disabled);
        border-color: var(--color-navy-100);
        cursor: not-allowed;
        opacity: .4;
      }
    }
  }

  [type='checkbox'] {
    + label {
      &:before {
        background-color: var(--color-dominant-white);
      }

      &:before,
      &:after {
        border-radius: 4px;
      }
    }

    &:checked + label:before {
      background-color: transparent;
    }

    &:checked + label:after {
      content: '';
      display: block;
      background-color: var(--color-dominant-on-dominant);
      opacity: 1;
      transform: scale(1);
    }
  }

  .icon {
    position: absolute;
    display: inline-flex;
    z-index: 9;
    transform: scale(1);
  }

  &.KolLab {
    &:hover {
      .customInput:before {
        border-color: var(--color-dominant-on-dominant);
        // transform: scale(1.2);
      }
    }

    label {
      &:before {
        border: var(--border-thin);
        z-index: 1;
      }
    }
    &:hover label:before {
      box-shadow: 0 0 0 5px var(--color-primary-100);
    }

    [type='checkbox'] {
      &:checked + label:after {
        background-color: var(--color-primary-300);
      }
    }
  }
}

.label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0 var(--spacing-spacing-xxx-small);
  padding: var(--spacing-spacing-thin) 0;
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-dominant-on-dominant);
  cursor: pointer;
  @include mixins.textShorten();

  &:first-letter {
    text-transform: uppercase;
  }

  &.disabled {
    color: var(--color-tertiary-300);
    cursor: not-allowed;
    opacity: .4;
  }

  &.KolLab {
    color: var(--color-dominant-on-dominant);
    font-size: 14px;
    font-weight: 500;
  }
}
