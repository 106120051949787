

.card {
    height: 64px;
    border-radius: var(--border-radius-medium);
    padding: var(--spacing-spacing-xxx-small) var(--spacing-spacing-xx-small);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-spacing-xx-small);

    cursor: pointer;

    transition: box-shadow 300ms ease-in-out, visibility 300ms ease;

    >.iconPlaceholder {
        width: 18px;
    }

    >.details {
        flex: 1;
        overflow: auto;

        >.filters {
            font-size: var(--font-size-font-size-3);
            font-weight: 400;
            color: var(--color-dominant-on-dominant);
            line-height: var(--line-height-var-text);

            display: flex;
            text-wrap: nowrap;
        }
    }

    >.actions {
        display: flex;
        align-items: center;
        gap: var(--spacing-spacing-xxx-small);
        visibility: hidden;
    }

    &:hover {
        transition: visibility 0s;
        box-shadow: var(--tile-shadow-hover);

        >.actions {
            visibility: visible;
        }
    }
}